<template>
  <view-card-template :loaded="loaded">
    <!-- <template #viewHeader>
      <h1>Voting</h1>
    </template> -->

    <template #cardTitle>
      Nomination Form
    </template>

    <template #cardBody>

      <!-- START Nomination Form  -->
      <div
        v-if="ballot"
        class="d-flex flex-column"
        style="height: 100%"
      >
        <v-container
          class="pa-6"
        >
          <v-form ref="form">
            <!-- Nominations for xyz header -->
            <v-row
              no-gutters
              class="mb-4"  
            >
              <v-col cols="auto">
                <div class="d-flex flex-column">
                  <h4>{{ awardType == null ? "Loading..." : `Nomination for "${awardType.title}"` }}</h4>
                  <div class="grey--text">
                    {{ [ballot.season, ballot.division].filter(_ => _ != null && _ != "").join(" | ") }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <!-- Player Select -->
            <v-row
              class="mb-6"
              no-gutters
            >
              <v-col cols="12">
                Select the player you would like to nominate for this award.
              </v-col>
              <v-col cols="12">
                <i class="grey--text body-2">NOTE: If a player is not selected from this list, stats will not be added to the nomination automatically.</i>
              </v-col>
              <v-col cols="6">
                <!-- Player Select -->
                <v-select
                  v-model="selectedPlayer"
                  @change="(newValue) => changeSelectedPlayer(newValue)"
                  :items="players"
                  item-text="formattedName"
                  label="Select Player..."
                  :menu-props="{
                    offsetY: true,
                    bottom: true
                  }"
                  :rules="[
                    (input) => { return !selectedPlayerIsRequired || input != null || 'Required'}
                  ]"
                  return-object
                  solo-inverted
                  hide-details="auto"
                />
              </v-col>
              <!-- Reverse Player Name Ordering -->
              <v-col cols="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="reversePlayerFormattedNames"
                      class="mx-2 my-1"
                      color="primary"
                      block
                    >
                      <v-icon>mdi-rotate-360</v-icon>
                    </v-btn>
                  </template>
                  Flip First and Last Names
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- Main Fields -->
            <v-row class="mt-0">
              <!-- First Name -->
              <v-col cols="6">
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  :rules="[
                    (input) => { return input != null && input.length > 0 || 'Required'}
                  ]"
                />
              </v-col>
              <!-- Last Name -->
              <v-col cols="6">
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  :rules="[
                    (input) => { return input != null && input.length > 0 || 'Required'}
                  ]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <!-- Position -->
              <v-col cols="6">
                <v-text-field
                  v-model="position"
                  label="Position"
                />
              </v-col>
              <!-- Hometown -->
              <v-col cols="6">
                <v-text-field
                  v-model="hometown"
                  label="Hometown"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <!-- Class Year -->
              <v-col cols="3">
                <v-text-field
                  v-model="classYear"
                  label="Class year"
                />
              </v-col>
              <!-- Jersey Number -->
              <v-col cols="3">
                <v-text-field
                  v-model="jerseyNumber"
                  label="Jersey Number"
                  type="number"
                />
              </v-col>
              <!-- High School -->
              <v-col cols="6">
                <v-text-field
                  v-model="highSchool"
                  label="High School"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="useCustomField"
              class="mt-0"
            >
              <!-- Custom Field -->
              <v-col cols="6">
                <v-text-field
                  v-model="customField"
                  :label="customFieldLabel"
                />
              </v-col>
            </v-row>

            <!-- Supporting Info -->
            <v-row
              class="mt-2"
            >
              <v-col cols="12">
                <div class="nominate-ck-editor">
                  <ckeditor
                    :editor="supportingInfo.editor"
                    v-model="supportingInfo.editorData"
                    :config="supportingInfo.editorConfig"
                  ></ckeditor>
                </div>
              </v-col>
            </v-row>

          </v-form>
        </v-container>

        <!-- START Page Actions -->
        <v-card-actions style="margin-top: auto;">
          <v-spacer/>
          <v-btn
            @click="navigateBack"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="previewNomination(), previewModal = true"
            color="primary"
            :disabled="selectedPlayer == null"
          >
            Preview
          </v-btn>
          <app-modal
            @cancel="previewModal = false"
            :value="previewModal"
            :header-text="awardType.title"
            width="75%"
          >
            <template #content>
              <v-expansion-panels
              v-if="ballot && ballot.awards.length"
              multiple
              focusable
              class="mt-10"
              >
                <v-expansion-panel
                  v-for="(table, index) in tables"
                  :key="index"
                >
                  <v-expansion-panel-header
                  > 
                    <div class="d-flex justify-space-between">                
                      <h4>
                        {{ table.award.awardType.title }}
                      </h4>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-data-table
                    :headers="[table.keyHeaders, table.statHeaders].flat()"
                    :items="displayedItems"
                    hide-default-footer
                    >
                    <template #item="{ item, expand, isExpanded }">
                      <tr>
                        <td class="font-weight-medium nominee-cell">
                          <div>
                            <div>{{ selectedPlayer.name }}</div>
                            <div class="text-caption">({{ selectedPlayer.teamName }})</div>
                          </div>
                        </td>
                      <td>
                        <v-btn
                          @click="expand(!isExpanded)"
                          icon
                        >
                          <v-icon
                            :style="{'transform': isExpanded ? 'rotate(-180deg)' : ''}"
                            style="transition: transform .3s;"
                          >
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </td>
                    <td
                      v-if="ballot.ballotType != 3 && table.statHeaders.length > 0"
                      class="mx-0 px-0"
                      style="font-size: 0.755rem;"
                    >
                      <div class="d-flex flex-column align-end">
                        <div style="opacity: 0;">(overall)</div>
                        <div>(conf)</div>
                      </div>
                    </td>
                    <!-- Stats Go Here -->
                    <td
                      v-for="(statHeader, index) in table.statHeaders"
                      :key="index"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-if="ballot.ballotType == 3"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{
                              item[statHeader.stat.mappingGroupName] && item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                              ? item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                              : "-"
                            }}
                          </div>
                          <div
                            v-else-if="ballot.ballotType != 3"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <div class="d-flex flex-column align-start">
                              <div>
                                {{
                                  item[statHeader.stat.mappingGroupName] && item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                  ? item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                  : "-"
                                }}
                              </div>
                              <div
                                v-for="(item, index) in conferenceTotals"
                                :key="index"
                              >
                                {{
                                  item && item[statHeader.stat.mappingGroupName] && item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                  ? item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                  : "-"
                                }}
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            v-bind="attrs"
                            v-on="on"
                          >
                            -
                          </div>
                        </template>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              <template v-slot:expanded-item="{ item, headers }">
                <td
                  :colspan="headers.length"
                  style="background-color: #ffffeb;"
                >
                  <v-data-table
                    v-if="ballot.ballotType == 3"
                    :headers="[table.weeklyKeyHeaders, table.statHeaders].flat()"
                    :items="gameStatsGames"
                    :items-per-page="-1"
                    style="background-color: #ffffeb;"
                    hide-default-footer
                    dense
                    flat
                  >
                    <template #item="itemProps">
                      <tr
                        v-for="(item, index) in itemProps.item"
                        :key="index"
                      >  
                        <td>{{ item.opponent }}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.result }}</td>
                        <!-- Stats Go Here-->
                        <td
                          v-for="(statHeader, index) in table.statHeaders"
                          :key="index"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ 
                                  item && item[statHeader.stat.mappingGroupName] && item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                  ? item[statHeader.stat.mappingGroupName][statHeader.stat.mappingStatName]
                                  : "-"
                                }}
                              </div>
                            </template>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-divider/>
                </td>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
          <v-row>
            <v-spacer/>
              <v-btn
              @click="previewModal = false"
              class="mt-8"
              >
              Close
              </v-btn>
            </v-row>
          </template>            
        </app-modal>
          <v-btn
            @click="submitNomination()"
            :loading="nominationSubmissionLoading"
            color="primary"
            class="ml-2"
          >
            Submit
          </v-btn>
        </v-card-actions>
        <!-- END Page Actions -->

      </div>
      <!-- END Nomination Form  -->

    </template>
  </view-card-template>
</template>

<script>
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import AppModal from '@/components/app/AppModal.vue'

import Controllers from "@/data/controllers"
import Identity from "@/app/Identity"

export default {
  name: "NominationForm",

  components: {
    ViewCardTemplate,
    ckeditor: CKEditor.component,
    AppModal,
  },

  props:  {
    ballotId: {
      type: Number,
      required: true,
    },
    awardTypeId: {
      type: Number,
      required: true,
    },
    nominationId: {
      type: Number,
      required: false,
    },
  },

  data: () => ({
    tables: null,
    weeklyKeyHeaders: [],
    weeklyStatHeaders: [],
    loaded: false,
    nominationSubmissionLoading: false,
    gameStats: [],
    gameStatsTotals: [],
    conferenceTotals: [],
    hasConferenceTotals: false,

    ballot: null,
    players: [],
    previewModal: false,
    
    selectedPlayer: null,
    selectedPlayerIsRequired: false,

    firstName: null,
    lastName: null,
    position: null,
    hometown: null,
    classYear: null,
    jerseyNumber: null,
    highSchool: null,
    customField: null,

    useCustomField: false,
    customFieldLabel: null,

    supportingInfo: {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        removePlugins: [
          'CKFinderUploadAdapter',
          'CKFinder',
          'EasyImage',
          'Image',
          'ImageCaption',
          'ImageStyle',
          'ImageToolbar',
          'ImageUpload',
          'MediaEmbed',
          'BlockQuote',
        ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'undo',
            'redo',
            '|',
            'insertTable',
          ]
        },
      },
    },
  }),

  computed: {
    awardType() {
      return this.ballot && this.ballot.awards.length
      ? this.ballot.awards.find(award => award.awardType.id == this.awardTypeId).awardType
      : null
    },
    displayedItems() {
      return this.ballot.ballotType === 3 ? this.gameStatsTotals : this.gameStats;
    },
  },

  methods: {
    navigateBack() {
      this.$router.go(-1)
    },

    changeSelectedPlayer(newSelectedPlayer) {
      // Use the player's fields as defaults in the override inputs
      this.firstName = newSelectedPlayer.formattedName.split(' ')[0]
      this.lastName = newSelectedPlayer.formattedName.split(' ').splice(1).join(' ')
    },

    reversePlayerFormattedNames() {
      // Flip the values in the first and last fields
      const temp = this.firstName
      this.firstName = this.lastName
      this.lastName = temp

      // Flip the formattedName for each of the conference players
      this.players.forEach(player => {
        const first = player.formattedName.split(' ')[0]
        const last = player.formattedName.split(' ').splice(1).join(' ')

        if(last != '') player.formattedName = `${last} ${first}`
      })
      this.players.sort((a, b) => a.formattedName.localeCompare(b.formattedName))
    },

    async previewNomination() {
      const sports = await this.$store.getters.conferenceSports;
      this.ballot.sportShortName = sports.find(s => s.globalSportId == this.ballot.globalSportId).shortName;

      if (this.ballot.ballotType != 3) {
        Controllers.ConferenceController.GetPlayerGameStats(
          encodeURI(this.selectedPlayer.name),
          Identity.GetUser().teamId,
          this.ballot.sportShortName,
          this.ballot.season,
          this.ballot.gamesOccuringBetweenStartDate,
          this.ballot.gamesOccuringBetweenEndDate,
          '0'
        )
        .then(res => {
          if(res && !res.hasError) {
          try {
          const gameStats = JSON.parse(res.data);
          const filteredStats = gameStats.games.find(gs => gs.opponent == "Totals");

          this.gameStats = [filteredStats];
          }
          catch (e) {
            this.$root.showSnackbar('There was an error fetching player stats', 5000)          }
          }
        }),
        Controllers.ConferenceController
          .GetPlayerGameStats(
            encodeURI(this.selectedPlayer.name),
            Identity.GetUser().teamId,
            this.ballot.sportShortName,
            this.ballot.season,
            this.ballot.gamesOccuringBetweenStartDate,
            this.ballot.gamesOccuringBetweenEndDate,
            '1'
          )
            .then(res => {
              if(res && !res.hasError) {
                try {
                  const gameStats = JSON.parse(res.data)
                  const filteredStats = gameStats.games.find(gs => gs.opponent == "Totals")
                  this.hasConferenceTotals = true

                  this.conferenceTotals = [filteredStats];

                }
                catch {
                  this.hasConferenceTotals = false
                }
              }
              else {
                this.hasConferenceTotals = false
              }
            })
      }
      else {
        Controllers.ConferenceController.GetPlayerGameStats(
          encodeURI(this.selectedPlayer.name),
          Identity.GetUser().teamId,
          this.ballot.sportShortName,
          this.ballot.season,
          this.ballot.gamesOccuringBetweenStartDate,
          this.ballot.gamesOccuringBetweenEndDate,
          '0'
        )
        .then(res => {
          if (res && !res.hasError) {
            try {
            const gameStats = JSON.parse(res.data);

            const gameStatsGames = gameStats.games.filter(gs => gs.opponent != "Totals");
            const gameStatsTotals = gameStats.games.find(gs => gs.opponent == "Totals");

            this.gameStatsTotals = [gameStatsTotals];
            this.gameStatsGames = [gameStatsGames];
            }
            catch (e) {
              this.$root.showSnackbar('There was an error fetching player stats', 5000)
            }
          }
        });
      }
      },

    async submitNomination() {
      if(this.nominationSubmissionLoading) return

      if(!this.$refs.form.validate()) {
        this.$root.showSnackbar('Failed to validate some field(s).', "error", 5000)
        return
      }

      this.nominationSubmissionLoading = true

      const nominationSubmission = {
        id: 0,
        nominatorId: 0,
        awardId: this.ballot.awards.find(award => award.awardTypeId == this.awardTypeId).id,
        ballotId: this.ballotId,
        
        firstName: this.firstName == null ? '' : this.firstName,
        lastName: this.lastName == null ? '' : this.lastName,
        hometown: this.hometown == null ? '' : this.hometown,
        highSchool: this.highSchool == null ? '' : this.highSchool,
        jersey: this.jerseyNumber == null ? '' : this.jerseyNumber,
        position: this.position == null ? '' : this.position,
        classYear: this.classYear,
        customField: this.customField,
        supportingInformation: this.supportingInfo.editorData,
        
        teamName: this.selectedPlayer && this.selectedPlayer.teamName
          ? this.selectedPlayer.teamName
          : Identity.GetUser().school,
        teamId: Identity.GetUser().teamId,
        apiPlayerName: this.selectedPlayer && this.selectedPlayer.name
          ? this.selectedPlayer.name
          : null,

        votes: [], // No time to make a seperate set of DTOs just for create/edit (at the moment).

        createdOn: new Date(),
        modifiedOn: new Date(),
      }

      if(this.nominationId == null) {
        // Creating a new nomination.
        const res = await Controllers.NominationController.CreateNomination(nominationSubmission)
        if(res && !res.hasError) {
          this.$root.showSnackbar('Nomination submitted successfully!', "success", 5000)
          this.$router.push({ name: "SelectAward", params: { ballotId: this.ballotId } })
        }
        else {
          this.$root.showSnackbar('There was an error submitting your nomination.', "error", 5000)
        }
      }
      else {
        // Updating an existing nomination.
        nominationSubmission.id = this.nominationId
        const res = await Controllers.NominationController.UpdateNomination(nominationSubmission)
        if(res && !res.hasError) {
          this.$root.showSnackbar('Nomination updated successfully!', "success", 5000)
          this.$router.push({ name: "SelectAward", params: { ballotId: this.ballot.id } })
        }
        else {
          this.$root.showSnackbar('There was an error updating your nomination.', "error", 5000)
        }
      }
      this.nominationSubmissionLoading = false
    },
  },

  async created() {
    const ballotRes = await Controllers.BallotController.GetBallot(this.ballotId)
    
    if(ballotRes && !ballotRes.hasError) {
      const ballot = ballotRes.data
      
      const sports = await this.$store.getters.conferenceSports
      const sport = sports.find(s => s.globalSportId == ballot.globalSportId)

      if(!sport.hasStats) this.selectedPlayerIsRequired = false
      else {
        // Make the player drowdown required based on the conference setting.
        Controllers.ConferenceSettingController.GetConferenceSettings()
          .then(res => {
            if(res && !res.hasError) {
              const settings = res.data
              this.selectedPlayerIsRequired = settings.choosePlayerIsRequired
            }
            else {
              // Error handling
            }
          })
      }

      ballot.awards.forEach(a => {
        a.statGroups.forEach(sg => {
          sg.stats.forEach(stat => {
            stat.mappingGroupName = stat.mapping.split("|")[0]
            stat.mappingStatName = stat.mapping.split("|")[1]
            stat.statGroupName = sg.name
          })
        })
      })

    const tables = ballot.awards
    .filter(award => award.awardType.id == this.awardTypeId)
    .map(award => {
      return {
        keyHeaders: [
          {
            text: 'Nominee',
            align: 'start',
            width: "200px",
            sortable: false,
            value: 'firstName',
            class: 'nominee-header',
          },
          {
            text: 'Supporting Info',
            width: "100px",
            sortable: false,
            value: 'data-table-expand'
          },
        ],
        weeklyKeyHeaders: [
          {
            text: 'Opponent',
            width: "150px",
            value: 'opponent'
          },
          {
            text: 'Date',
            width: "100px",
            value: 'date',
          },
          {
            text: 'Result',
            width: "100px",
            value: 'result',
          },
        ],
        award: {
          ...award
        },
        statHeaders: award.statGroups
        .map(sg => sg.stats)
        .flat()
        .map(stat => {
          return {
            text: stat.abbreviation,
            value: `${ballot.ballotType == 3 ? 'totals' : 'overallTotals'}.${stat.mappingGroupName}.${stat.mappingStatName}`,
            width: "40px",
            stat,
        }})
      }});

          // Table structure specific to non-weekly awards.
          if(ballot.ballotType != 3) {
            tables.forEach(table => {
              if (table.statHeaders.length > 0) {
                if (table.keyHeaders.length < 3)
                  table.keyHeaders.push({
                    text: "", // (conf) tag
                    align: "start",
                    width: "10px",
                    sortable: false,
                  })
              }
              else table.keyHeaders.pop()
            })
          }

      // Custom field stuffs.
      this.useCustomField = ballot.useCustomField
      this.customFieldLabel = ballot.customFieldLabel

      // Get players.
      const playersRes = await Controllers.ConferenceController.ListTeamPlayers(sport.shortName, ballot.season)
      if(playersRes && !playersRes.hasError) {
        playersRes.data.forEach(player => {
          // Replace any comma seperation with spaces
          const nameParts = player.name.split(/[,\s]\s*/)
          player.formattedName = nameParts.join(" ")
        })
        this.players = playersRes.data.filter(a => a.checkname !== "TEAM").sort((a, b) => a.formattedName.localeCompare(b.formattedName))
      }
      else {
        // Error handling
      }

      // Editing an existing nomination.
      if(this.nominationId) {
        this.$root.updateTitle("Edit Nomination")
        const awardWithNomination = ballot.awards.find(award => award.awardType.id == this.awardTypeId)
        const nomination = awardWithNomination.nominations.find(n => n.id == this.nominationId)

        this.firstName = nomination.firstName
        this.lastName = nomination.lastName
        this.hometown = nomination.hometown
        this.highSchool = nomination.highSchool
        this.jerseyNumber = nomination.jersey

        this.customField = nomination.customField

        if(nomination.apiPlayerName) {
          this.selectedPlayer = this.players.find(p => p.name == nomination.apiPlayerName)
          if(this.selectedPlayer == null) {
            const flippedName = `${nomination.apiPlayerName.split(" ")[1]}, ${nomination.apiPlayerName.split(" ")[0]}`
            this.selectedPlayer = this.players.find(p => p.name == flippedName)
          }
        }

        this.supportingInfo.editorData = nomination.supportingInformation
        this.position = nomination.position
        this.classYear = nomination.classYear
      }
      else {
        // Creating a new nomination.
        this.$root.updateTitle("Add Nomination")
      }

      this.tables = tables
      this.ballot = ballot
    }
    else {
      // Error handling
    }

    this.loaded = true
  }
}
</script>

<style lang="scss">
.nominate-ck-editor .ck-editor__editable {
  min-height: 200px;
  max-height: 800px;
}
</style>